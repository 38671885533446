html {
  min-height: -webkit-fill-available;
  width: 100vw;
  height: 100vh;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
  min-height: -webkit-fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100vw;
  height: 100vh;
}

/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@media only screen and (max-width: 600px) {
  body {
    /* background-color: lightblue; */
  }
  #card-title-img {
    /* display: none; */
  }
}
